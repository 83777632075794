// This component can be added to App.tsx/EntityPage.tsx TechDocsAddons components to enable pan/zoom for Mermaid diagrams
import { createPlugin } from '@backstage/core-plugin-api';
import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';
import { useEffect } from 'react';
import svgPanZoom from 'svg-pan-zoom';
import pako from 'pako';

// Define the options for pan/zoom
interface PanZoomOptions {
  enablePanZoom: boolean;
  fit: boolean;
  center: boolean;
  controlIconsEnabled: boolean;
}

// Track processed diagrams
const processedMermaidDivs = new Set<Element>();

// Initialize pan/zoom for a given SVG
const initializePanZoom = (svg: SVGElement, options: PanZoomOptions) => {
  return svgPanZoom(svg, {
    zoomEnabled: options.enablePanZoom,
    controlIconsEnabled: options.controlIconsEnabled,
    fit: options.fit,
    center: options.center,
    zoomScaleSensitivity: 0.3,
    minZoom: 0.1,
    maxZoom: 30,
  });
};

// Fix SVG dimensions based on the parent container
const adjustSvgDimensions = (mermaidDiv: HTMLElement, svg: SVGElement, parentDiv: HTMLElement) => {
  const widthAttr = parentDiv.getAttribute('data-width');
  const heightAttr = parentDiv.getAttribute('data-height');
  mermaidDiv.style.width = widthAttr ?? mermaidDiv.style.width;
  mermaidDiv.style.height = heightAttr ?? mermaidDiv.style.height;

  svg.style.width = `${mermaidDiv.offsetWidth}px`;
  svg.style.height = `${mermaidDiv.offsetHeight}px`;
  svg.style.overflow = 'auto';
};

// Create a modal to display the SVG fullscreen
const showFullscreenModal = (svg: SVGElement) => {
  const modal = document.createElement('div');
  modal.style.position = 'fixed';
  modal.style.top = '0';
  modal.style.left = '0';
  modal.style.width = '100vw';
  modal.style.height = '100vh';
  modal.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
  modal.style.zIndex = '10000';
  modal.style.display = 'flex';
  modal.style.justifyContent = 'center';
  modal.style.alignItems = 'center';

  const closeButton = document.createElement('button');
  closeButton.textContent = 'CLOSE';
  closeButton.style.position = 'absolute';
  closeButton.style.top = '20px';
  closeButton.style.right = '20px';
  closeButton.style.width = '60px';
  closeButton.style.height = '30px';
  closeButton.style.border = 'none';
  closeButton.style.borderRadius = '4px';
  closeButton.style.backgroundColor = '#e0e0e0';
  closeButton.style.color = '#000';
  closeButton.style.fontSize = '14px';
  closeButton.style.textAlign = 'center';
  closeButton.style.lineHeight = '30px';
  closeButton.style.boxShadow = '0px 2px 5px rgba(0, 0, 0, 0.2)';
  closeButton.style.cursor = 'pointer';

  closeButton.addEventListener('click', () => {
    modal.remove();
  });

  const svgClone = svg.cloneNode(true) as SVGElement;
  svgClone.style.width = '90%';
  svgClone.style.height = '90%';
  svgClone.style.backgroundColor = '#fff';

  modal.appendChild(svgClone);
  modal.appendChild(closeButton);
  document.body.appendChild(modal);

  initializePanZoom(svgClone, {
    enablePanZoom: true,
    fit: true,
    center: true,
    controlIconsEnabled: true,
  });
};

// Add buttons to Mermaid diagram
const addButtons = (mermaidDiv: HTMLElement, svg: SVGElement, parentDiv: HTMLElement) => {
  const createButton = (
    text: string,
    onClick: () => void,
    styles: Partial<CSSStyleDeclaration>
  ) => {
    const button = document.createElement('button');
    button.innerHTML = text;
    Object.assign(button.style, {
      position: 'absolute',
      right: '5px',
      width: '80px',
      height: '25px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#A9A9A9',
      color: '#FFF',
      fontSize: '12px',
      fontWeight: 'bold',
      textAlign: 'center',
      lineHeight: '25px',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
      textDecoration: 'none',
      opacity: '0', // Start as invisible
      transition: 'opacity 0.3s',
      ...styles,
    });
    button.addEventListener('click', onClick);
    return button;
  };

  // Check if the parentDiv allows the maximize button
  const enableMaximizeButton = parentDiv.getAttribute('data-maximize-button') !== 'false';

  // Check if the parentDiv allows the mermaid.live button
  const enableMermaidLiveButton =
    parentDiv.getAttribute('data-mermaid-live-button') !== 'false';

  let maximizeButton: HTMLButtonElement | null = null;
  if (enableMaximizeButton) {
    maximizeButton = createButton('[&nbsp;&nbsp;]', () => showFullscreenModal(svg), {
      top: '5px',
      width: '25px',
    });
  }

  let exportButton: HTMLButtonElement | null = null;
  if (enableMermaidLiveButton) {
    exportButton = createButton('mermaid.live', () => {
      const preElement = parentDiv.querySelector('pre, code');
      if (preElement?.textContent) {
        const serializedSyntax = serializeForMermaidLive(preElement.textContent);
        const mermaidLiveUrl = `https://mermaid.live/edit#pako:${serializedSyntax}`;
        window.open(mermaidLiveUrl, '_blank');
      }
    }, { top: '35px' });
  }

  mermaidDiv.style.position = 'relative';
  mermaidDiv.addEventListener('mouseenter', () => {
    if (maximizeButton) maximizeButton.style.opacity = '1';
    if (exportButton) exportButton.style.opacity = '1';
  });
  mermaidDiv.addEventListener('mouseleave', () => {
    if (maximizeButton) maximizeButton.style.opacity = '0';
    if (exportButton) exportButton.style.opacity = '0';
  });

  if (maximizeButton) {
    mermaidDiv.appendChild(maximizeButton);
  }
  if (exportButton) {
    mermaidDiv.appendChild(exportButton);
  }
};


// Serialize Mermaid syntax for Mermaid Live
const serializeForMermaidLive = (mermaidSyntax: string): string => {
  const mermaidLiveJson = {
    code: mermaidSyntax,
    autoSync: true,
    editorMode: 'code',
    mermaid: '{ "theme": "dark" }', // when trying the default theme, the mermaid live editor flickers so we use dark theme
    panZoom: true,
    rough: false,
    updateDiagram: true
  };
  const compressed = pako.deflate(JSON.stringify(mermaidLiveJson));
  return btoa(String.fromCharCode(...compressed));
};

// Process Mermaid diagrams
const handleMermaidDivs = (mermaidDivs: NodeListOf<Element>) => {
  mermaidDivs.forEach((mermaidDiv) => {
    if (processedMermaidDivs.has(mermaidDiv)) return;
    const parentDiv = mermaidDiv.closest<HTMLDivElement>('div[data-pan-zoom]');
    if (!parentDiv || mermaidDiv.tagName === 'PRE') return;
    const svg = mermaidDiv.querySelector<SVGElement>('svg');
    if (!svg) return;

    adjustSvgDimensions(mermaidDiv as HTMLElement, svg, parentDiv);
    addButtons(mermaidDiv as HTMLElement, svg, parentDiv);

    const options: PanZoomOptions = {
      enablePanZoom: parentDiv?.getAttribute('data-pan-zoom') === 'true',
      fit: parentDiv?.getAttribute('data-pan-zoom-fit') === 'true',
      center: parentDiv?.getAttribute('data-pan-zoom-center') === 'true',
      controlIconsEnabled: parentDiv?.getAttribute('data-pan-zoom-control-icons') === 'true',
    };

    initializePanZoom(svg, options);
    processedMermaidDivs.add(mermaidDiv);
  });
};

// Main component
const MermaidPanZoomAddon = (): null => {
  useEffect(() => {
    const shadowHost = document.querySelector<HTMLDivElement>('[data-testid="techdocs-native-shadowroot"]');
    if (!shadowHost?.shadowRoot) {
      return;
    }

    const observer = new MutationObserver(() => {
      const mermaidDivs = shadowHost.shadowRoot!.querySelectorAll('div .mermaid');
      if (mermaidDivs.length > 0) {
        handleMermaidDivs(mermaidDivs);
      }
    });

    observer.observe(shadowHost.shadowRoot, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return null;
};

export default MermaidPanZoomAddon;

// Plugin definition
export const techdocsAddonMermaidPanZoomAddonPlugin = createPlugin({
  id: 'techdocs-addon-mermaid-pan-zoom',
});

// Extension definition
export const MermaidPanZoom = techdocsAddonMermaidPanZoomAddonPlugin.provide(
  createTechDocsAddonExtension({
    name: 'MermaidPanZoomDiagram',
    location: TechDocsAddonLocations.Content,
    component: MermaidPanZoomAddon,
  }),
);
